<template>
  <div>
    <div class="d-flex">
      <div class="half-screen mr-5">
        <div v-for="(groupedEvents, date) in eventsToday" :key="date">
          <div class="d-flex pl-4 text-h6 font-weight-bold">
            <span class="text-h5 font-weight-bold pb-1">{{ $t('economicCalendar.today') }} ({{ today | convertDateMD }})</span>
          </div>
          <v-data-table 
            :headers="headers" 
            :items="groupedEvents" 
            item-key="id" 
            hide-default-header
            hide-default-footer 
            class="transparent"
            :expanded="expanded"
            :items-per-page="100"
          >
            <template v-slot:[`item.expand`]="{ item }">
              <v-icon @click="toggleExpand(item)" v-if="item.comment">
                {{ expanded.includes(item) ? 'mdi-chevron-up' : 'mdi-chevron-down' }}
              </v-icon>
            </template>
            <template #[`item.date`]="{ item }">
              <td class="time-column">
                <span>{{ formatTime(item.date) }}</span>
              </td>
            </template>
            <template #[`item.country`]="{ item }">
              <td class="icon-column">
                <span>{{ item.country }}</span>
              </td>
            </template>
            <template #[`item.importance`]="{ item }">
              <td class="icon-column">
                <v-icon>{{ getSignalIcon(item.importance) }}</v-icon>
              </td>
            </template>
            <template #[`item.title`]="{ item }">
              <td class="headline-column">
                <span 
                  v-if="item.source_url" 
                  @click="openEvent(item.source_url)" 
                  style="cursor: pointer;"
                >
                  {{ item.title }}
                </span>
                <span v-else>{{ item.title }}</span>
              </td>
            </template>
            <template v-slot:expanded-item="{ item }">
              <td :colspan="headers.length">
                <v-card flat class="transparent">
                  <v-card-text>{{ item.comment }}</v-card-text>
                </v-card>
              </td>
            </template>
          </v-data-table>
        </div>
        <div v-if="Object.keys(eventsToday).length < 1 && eventsToday">
          <div class="d-flex pl-4 text-h6 font-weight-bold">
            <span class="text-h5 font-weight-bold pb-1">{{ $t('economicCalendar.today') }} ({{ today | convertDateMD }})</span>
          </div>
          <div class="pl-4">
            {{ $t('economicCalendar.noEventsToday') }}
          </div>
        </div>
      </div>

      <div class="half-screen ml-5">
        <div v-for="(groupedEvents, date) in eventsNextDay" :key="date">
          <div class="d-flex pl-4 text-h6 font-weight-bold">
            <span class="text-h5 font-weight-bold pb-1">{{ getDayName(nextDay) }} ({{ nextDay | convertDateMD }})</span>
          </div>
          <v-data-table 
            :headers="headers" 
            :items="groupedEvents" 
            item-key="id" 
            hide-default-header
            hide-default-footer 
            class="transparent"
            :expanded="expanded"
            :items-per-page="100"
          >
            <template v-slot:[`item.expand`]="{ item }">
              <v-icon @click="toggleExpand(item)" v-if="item.comment">
                {{ expanded.includes(item) ? 'mdi-chevron-up' : 'mdi-chevron-down' }}
              </v-icon>
            </template>
            <template #[`item.date`]="{ item }">
              <td class="time-column">
                <span>{{ formatTime(item.date) }}</span>
              </td>
            </template>
            <template #[`item.country`]="{ item }">
              <td class="icon-column">
                <span>{{ item.country }}</span>
              </td>
            </template>
            <template #[`item.importance`]="{ item }">
              <td class="icon-column">
                <v-icon>{{ getSignalIcon(item.importance) }}</v-icon>
              </td>
            </template>
            <template #[`item.title`]="{ item }">
              <td class="headline-column">
                <span 
                  v-if="item.source_url" 
                  @click="openEvent(item.source_url)" 
                  style="cursor: pointer;"
                >
                  {{ item.title }}
                </span>
                <span v-else>{{ item.title }}</span>
              </td>
            </template>
            <template v-slot:expanded-item="{ item }">
              <td :colspan="headers.length">
                <v-card flat class="transparent">
                  <v-card-text>{{ item.comment }}</v-card-text>
                </v-card>
              </td>
            </template>
          </v-data-table>
        </div>
        <div v-if="Object.keys(eventsNextDay).length < 1 && eventsNextDay">
          <div class="d-flex pl-4 text-h6 font-weight-bold">
            <span class="text-h5 font-weight-bold pb-1">{{ getDayName(nextDay) }} ({{ nextDay | convertDateMD }})</span>
          </div>
          <div class="pl-4">
            {{ $t('economicCalendar.noEventsTomorrow') }}
          </div>
        </div>
      </div>
    </div>

    <div
      v-if="Object.keys(eventsOtherDays).length > 0"
      class="mt-5"
      style="
        border: 1px solid #ccc;
        border-radius: 8px;
      "
    >
      <div
        class="d-flex align-center pa-3"
        @click="toggleOtherDays"
        style="cursor: pointer;"
      >
        <v-icon class="mr-2">
          {{ showOtherDays ? 'mdi-chevron-up' : 'mdi-chevron-down' }}
        </v-icon>
        <span class="text-h5 font-weight-bold">{{ $t('economicCalendar.more') }}</span>
      </div>

      <div v-if="showOtherDays">
        <div v-for="(groupedEvents, date) in eventsOtherDays" :key="date">
          <div class="d-flex table-title pl-4 py-1">
            <div class="header-column">
              <span>{{ formatCustomDate(date) }}</span>
            </div>
          </div>

          <v-data-table 
            :headers="headers" 
            :items="groupedEvents"
            item-key="id"
            hide-default-header
            hide-default-footer
            class="transparent"
            :expanded="expanded"
            :items-per-page="100"
          >
            <template v-slot:[`item.expand`]="{ item }">
              <v-icon @click="toggleExpand(item)" v-if="item.comment">
                {{ expanded.includes(item) ? 'mdi-chevron-up' : 'mdi-chevron-down' }}
              </v-icon>
            </template>
            <template #[`item.date`]="{ item }">
              <td class="time-column">
                <span>{{ formatTime(item.date) }}</span>
              </td>
            </template>
            <template #[`item.country`]="{ item }">
              <td class="icon-column">
                <span>{{ item.country }}</span>
              </td>
            </template>
            <template #[`item.importance`]="{ item }">
              <td class="icon-column">
                <v-icon>{{ getSignalIcon(item.importance) }}</v-icon>
              </td>
            </template>
            <template #[`item.title`]="{ item }">
              <td class="headline-column">
                <span 
                  v-if="item.source_url" 
                  @click="openEvent(item.source_url)" 
                  style="cursor: pointer;"
                >
                  {{ item.title }}
                </span>
                <span v-else>{{ item.title }}</span>
              </td>
            </template>
            <template v-slot:expanded-item="{ item }">
              <td :colspan="headers.length">
                <v-card flat class="transparent">
                  <v-card-text>{{ item.comment }}</v-card-text>
                </v-card>
              </td>
            </template>
          </v-data-table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "pinia"
import { useAlgoRFQStore } from "./../store/index"
import { i18n } from "../plugins/vuetify"

export default {
  data() {
    return {
      expanded: [],
      showOtherDays: false,
    }
  },
  filters: {
    convertDateMD(unixTimestamp) {
      const date = new Date(unixTimestamp)
      const day = date.getDate().toString().padStart(2, '0')
      const month = (date.getMonth() + 1).toString().padStart(2, '0')

      return i18n.locale === 'pt' ? `${day}/${month}` : `${month}/${day}`
    }
  },
  mounted () {
    // this.checkEventsInLocalStorage()
  },
  computed: {
    ...mapState(useAlgoRFQStore, [
      "getFXMarketEconomicCalendar",
    ]),
    headers() {
      return [
        { text: '', value: 'expand', align: 'start' },
        { text: this.$t('economicCalendar.date'), value: 'date' },
        { text: this.$t('economicCalendar.country'), value: 'country' },
        { text: this.$t('economicCalendar.importance'), value: 'importance' },
        { text: this.$t('economicCalendar.title'), value: 'title' },
      ]
    },
    convertedEvents() {
      if (!this.getFXMarketEconomicCalendar) return []
      return this.getFXMarketEconomicCalendar.map(
        (event) => {
          let result = {}
          result["actual"] = event.actual
          result["actualRaw"] = event.actualRaw
          result["category"] = event.category
          result["comment"] = event.comment
          result["country"] = event.country
          result["currency"] = event.currency
          const dateString = event.date
          const date = new Date(dateString)
          const convertedEvent = date.getTime()
          result["date"] = convertedEvent
          result["forecast"] = event.forecast
          result["forecastRaw"] = event.forecastRaw
          result["id"] = event.id
          result["importance"] = event.importance
          result["indicator"] = event.indicator
          result["period"] = event.period
          result["previous"] = event.previous
          result["previousRaw"] = event.previousRaw
          result["source"] = event.source
          result["source_url"] = event.source_url
          result["ticker"] = event.ticker
          result["title"] = event.title

          return result
        }
      )
    },
    sortedEvents() {
      return this.convertedEvents.slice().sort((a, b) => a.date - b.date)
    },
    groupedEventsByDate() {
      return this.sortedEvents.reduce((groups, event) => {
        const date = this.formatDate(event.date)
        if (!groups[date]) {
          groups[date] = []
        }
        groups[date].push(event)
        return groups
      }, {})
    },
    today() {
      const today = new Date()
      today.setHours(0, 0, 0, 0)
      return today.getTime()
    },
    nextDay() {
      const today = new Date()
      today.setHours(0, 0, 0, 0)

      let nextDay = new Date(today)
      nextDay.setDate(today.getDate() + 1)

      const dayOfWeek = nextDay.getDay()

      if (dayOfWeek === 6) {
        nextDay.setDate(nextDay.getDate() + 2)
      } 
      else if (dayOfWeek === 0) {
        nextDay.setDate(nextDay.getDate() + 1)
      }

      return nextDay.getTime()
    },
    eventsToday() {
      return Object.keys(this.groupedEventsByDate).reduce((acc, dateKey) => {
        const eventDate = new Date(this.convertDateMDY(dateKey)).setHours(0, 0, 0, 0)
        if (eventDate === this.today) {
          acc[dateKey] = this.groupedEventsByDate[dateKey]
        }
        return acc
      }, {})
    },
    eventsNextDay() {
      return Object.keys(this.groupedEventsByDate).reduce((acc, dateKey) => {
        const eventDate = new Date(this.convertDateMDY(dateKey)).setHours(0, 0, 0, 0)
        if (eventDate === this.nextDay) {
          acc[dateKey] = this.groupedEventsByDate[dateKey]
        }
        return acc
      }, {})
    },
    eventsOtherDays() {
      const dayOfWeek = new Date().getDay()
      const excludeNextDay = dayOfWeek >= 0 && dayOfWeek <= 4

      return Object.keys(this.groupedEventsByDate).reduce((acc, dateKey) => {
        const eventDate = new Date(this.convertDateMDY(dateKey)).setHours(0, 0, 0, 0)

        if (eventDate !== this.today && (!excludeNextDay || eventDate !== this.nextDay)) {
          acc[dateKey] = this.groupedEventsByDate[dateKey]
        }
        return acc
      }, {})
    },
  },
  methods: {
    getSignalIcon(relevance) {
      switch (relevance) {
        case -1:
          return 'mdi-signal-cellular-1'
        case 0:
          return 'mdi-signal-cellular-2'
        case 1:
          return 'mdi-signal-cellular-3'
        default:
          return ''
      }
    },
    formatDate(timestamp) {
      const date = new Date(timestamp)
      const options = { day: '2-digit', month: '2-digit', year: 'numeric' }
      return new Intl.DateTimeFormat(this.$i18n.locale, options).format(date)
    },
    formatCustomDate(dateString) {
      let day, month, year
      if (this.$i18n.locale === 'pt') {
        const parts = dateString.split('/')
        day = parseInt(parts[0], 10)
        month = parseInt(parts[1], 10)
        year = parseInt(parts[2], 10)
      } else if (this.$i18n.locale === 'en') {
        const parts = dateString.split('/')
        month = parseInt(parts[0], 10)
        day = parseInt(parts[1], 10)
        year = parseInt(parts[2], 10)
      }
      const date = new Date(year, month - 1, day)
      const options = { weekday: 'long', day: 'numeric', month: 'long' }
      return new Intl.DateTimeFormat(this.$i18n.locale, options).format(date)
    },
    formatTime(timestamp) {
      let date = new Date()
      if (timestamp) {
        date = new Date(timestamp)
      }
      const timeOptions = { hour: '2-digit', minute: '2-digit', hour12: true }
      return date.toLocaleTimeString(this.$i18n.locale, timeOptions)
    },
    getDayName(date) {
      const today = new Date()
      const tomorrow = new Date()
      tomorrow.setDate(today.getDate() + 1)

      const eventDate = new Date(date)

      eventDate.setHours(0, 0, 0, 0)
      tomorrow.setHours(0, 0, 0, 0)
      today.setHours(0, 0, 0, 0)

      if (eventDate.getTime() === tomorrow.getTime()) {
        return this.$i18n.locale === 'pt' ? 'Amanhã' : 'Tomorrow'
      }

      const locale = this.$i18n.locale

      const dayName = locale === 'pt'
        ? 'Segunda-feira'
        : 'Monday'

      return dayName
    },
    convertDateMDY (dateKey) {
      if (this.$i18n.locale === 'pt') {
        const [day, month, year] = dateKey.split('/')
        return `${month}/${day}/${year}`
      }
      return dateKey
    },
    openEvent(url) {
      window.open(url, "_blank")
    },
    toggleExpand(item) {
      if (this.expanded.includes(item)) {
        this.expanded = this.expanded.filter(i => i !== item)
      } else {
        this.expanded.push(item)
      }
    },
    toggleOtherDays() {
      this.showOtherDays = !this.showOtherDays
    }
  }
}
</script>

<style scoped>
.table-title {
  background-color: #151515
}

.time-column {
  width: 10%
}

.icon-column {
  width: 4%
}

.headline-column {
  width: 82%
}

.half-screen {
  width: 50%;
}
</style>
