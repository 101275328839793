<template>
  <!-- style="max-width: 115px; min-width: 115px;" -->
  <!-- <div> 
    <v-card
      class="py-1 px-2 d-flex flex-column"
      max-width="150"
      min-width="150"
      outlined
      elevation="0"
      height="78"
      :color="'#000000'"
      style="border: thin solid rgba(255, 255, 255, 0.12);"
      @click="reveal = !reveal"
    >
    -->
      <div
        class="d-flex flex-column px-3"
        style="min-width: 135px; max-width: 135px;"
      >
        <span 
          class="text-body-1 text--secondary mr-1 text-no-wrap"
        >
          {{ $t('toolbar.avgPtax') }}
        </span>
        <span
          class="text-h4"
        >
          {{ avgPtax }}
        </span>
      </div>
    <!--
    </v-card>
    -->
  <!-- </div> -->
</template>

<script>
import { mapState } from "pinia"
import { useAlgoRFQStore } from "./../store/index"

export default {
  data () {
    return {
    }
  },
  mounted () {
  },
  methods: {
  },
  computed: {
    ...mapState(useAlgoRFQStore, [
      "getAvgPtax"
    ]),
    avgPtax () {
      if (!this.getAvgPtax) return
      return this.getAvgPtax[this.getAvgPtax.length - 1].avg_ptax
    }
  }
}
</script>

<style scoped>
.v-card--reveal {
  bottom: 0;
  opacity: 1 !important;
  position: absolute;
  width: 10
}
</style>