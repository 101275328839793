<template>
  <div>
    <v-progress-linear
      v-model="timeLeftPercent"
      height="60"
      style="pointer-events: none"
      :color="progressColor"
    >
      <span 
        :class="[
          'font-weight-bold',
          'text-subtitle-1',
          'white--text'
        ]"
      >
        {{ $t('quoteInfo.timeLeft').toUpperCase() }}: {{ formattedTimeLeft }}
      </span>
    </v-progress-linear>
  </div>
</template>

<script>
export default {
  props: {
    timeLimit: {
      type: Number,
      required: true
    }
  },
  data () {
    return {
      timePassed: 0,
      initialTimeLeft: 0,
      timerInterval: null,
      timeLeftPercent: 100
    }
  },
  computed: {
    timeLimitFormatted() {
      // Converte o timeLimit para segundos (caso venha em milissegundos ou com frações)
      const timeLimitInSeconds = Math.floor(this.timeLimit / 1000); // Converter para segundos e arredondar
      const currentTimeInSeconds = Math.floor(new Date().getTime() / 1000); // Tempo atual em segundos

      // Calcula a diferença entre o timeLimit e o tempo atual
      const timeDifferenceInSeconds = timeLimitInSeconds - currentTimeInSeconds;

      return timeDifferenceInSeconds; // Retorna o tempo restante em segundos
    },
    formattedTimeLeft() {
      const timeLeft = this.timeLeft
      const minutes = Math.floor(timeLeft / 60)
      let seconds = timeLeft % 60
      if (seconds < 10) {
        seconds = `0${seconds}`
      }
      return `${minutes}:${seconds}`
    },
    timeLeft() {
      return this.initialTimeLeft - this.timePassed
    },
    quarterTime() {
      return this.initialTimeLeft / 4
    },
    greenProgress () {
      return this.timeLeft >= this.fortyPercentProgress
    },
    yellowProgress () {
      return this.timeLeft >= this.fifteenPercentProgress && this.timeLeft < this.fortyPercentProgress
    },
    redProgress () {
      return this.timeLeft < this.fifteenPercentProgress
    },
    fortyPercentProgress () {
      return this.initialTimeLeft * 0.40
    },
    fifteenPercentProgress () {
      return this.initialTimeLeft * 0.15
    },
    progressColor () {
      if (this.greenProgress) {
        return "alive" 
      }
      if (this.yellowProgress) {
        return "orangeColor"
      } 
      return "attention"
    }
  },
  mounted() {
    this.initialTimeLeft = this.timeLimitFormatted
    this.startTimer()
  },
  beforeDestroy () {
    clearInterval(this.timerInterval)
  },
  methods: {
    startTimer() {
      const onePercent = (100 / this.initialTimeLeft) * 1
      this.timerInterval = setInterval(() => {
        this.timePassed += 1
        this.timeLeftPercent = this.timeLeftPercent - onePercent
        if (this.timePassed >= this.initialTimeLeft) {
          clearInterval(this.timerInterval)
          this.$emit("timeDue")
        } 
      }, 1000)
    }
  }
}
</script>