<template>
  <div>
    <v-row no-gutters class="mb-8 px-8">
      <v-col
        xs="0"
        sm="0"
        md="0"
        lg="1"
        xl="1"
      >
      </v-col>
  
      <v-col
        xs="12"
        sm="12"
        md="12"
        lg="10"
        xl="10"
        class="pt-8"
      >
        <div class="d-flex mb-10 pb-15">
          <div 
            class="text-h3 font-weight-light" 
            style="
              position: absolute;
              left: 50%;
              transform: translateX(-50%);
            "
          >
            {{ $t('title.reportDashboard') }}
          </div>
          <v-spacer></v-spacer>
          <div class="font-weight-light">
            <!-- {{ now }} -->
          </div>
        </div>
  
        <div class="d-flex py-4 align-center">
          <div class="d-flex align-center">
            <v-btn
              color="primary"
              small
              outlined
              class="mr-6"
              @click="expandAll()"
            >
              <span class="white--text">
                {{ $t('instructions.expandAll') }}
              </span>
            </v-btn>
  
            <v-btn
              color="primary"
              small
              outlined
              class="mr-8"
              @click="contractAll()"
            >
              <span class="white--text">
                {{ $t('instructions.contractAll') }}
              </span>
            </v-btn>
  
            <v-menu
              v-model="menu2"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="date2"
                  :label="$t('extras.start')"
                  prepend-icon="mdi-calendar"
                  readonly
                  dense
                  v-bind="attrs"
                  v-on="on"
                  style="width: 170px;"
                  hide-details
                  class="mr-6"
                >
                </v-text-field>
              </template>
  
              <v-date-picker
                v-model="date2"
                no-title
                color="primary"
                @input="menu2 = false"
                :locale="locale"
              >
              </v-date-picker>
            </v-menu>
  
            <v-menu
              v-model="menu3"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="date"
                  :label="$t('extras.end')"
                  prepend-icon="mdi-calendar"
                  readonly
                  dense
                  v-bind="attrs"
                  v-on="on"
                  style="width: 170px;"
                  hide-details
                  class="mr-6"
                >
                </v-text-field>
              </template>
  
              <v-date-picker
                v-model="date"
                no-title
                @input="menu3 = false"
                color="primary"
              >
              </v-date-picker>
            </v-menu>
  
            <v-btn
              color="primary"
              small
              outlined
              disabled
            >
              <span class="white--text text--disabled">
                {{ $t('instructions.filter') }}
              </span>
            </v-btn>
          </div>
  
          <v-spacer></v-spacer>
  
          <div>
            <v-btn
              color="primary"
              small
              outlined
              class="mx-8"
              disabled
            >
              <span class="white--text text--disabled">
                {{ $t('instructions.exportSpreadsheet') }}
              </span>
            </v-btn>
  
            <v-btn
              color="primary"
              small
              outlined
              @click="exportPDF"
            >
              <span class="white--text">
                {{ $t('instructions.exportPdf') }}
              </span>
            </v-btn>
          </div>
        </div>
  
        <v-card class="py-4" outlined>
          <v-data-table
            :headers="headers"
            :items="reportsData"
            :items-per-page="5"
            class="elevation-0"
            :single-expand="false"
            :expanded.sync="expanded"
            item-key="rfq_id"
            show-expand
            :footer-props="{
              'items-per-page-options': [10, 20, 30, 50]
            }"
          >
            <template #[`item.rfq_deal_time`]="{ item }">
              <div class="d-flex flex-column">
                <span>{{ item.rfq_deal_time | formatDate }}</span>
              </div>
            </template>
  
            <template #[`item.rfq_amount`]="{ item }">
              <v-chip
                small
                :text-color="item.rfq_side === 'BUY' ? 'alive' : 'orangeColor'"
                :color="item.rfq_side === 'BUY' ? 'aliveTransp' : 'yellowTransp'"
              >
                <span class="font-weight-bold">
                  {{ item.rfq_amount | formatPrice }}
                </span>
              </v-chip>
            </template>
  
            <template #[`item.rfq_spotsett`]="{ item }">
              <span>
                {{ item.rfq_spotsett.toUpperCase() }}
              </span>
            </template>
  
            <template v-slot:expanded-item="{ headers, item }">
              <td :colspan="headers.length" class="pa-8" style="background: #121212;">
                <div class="text-h5 font-weight-light mb-5">
                  {{ $t('reports.expand.title') }}
                </div>
  
                <div class="mb-10">
                  <v-row no-gutters>
                    <!-- <v-col cols="3">
                      <span>
                        {{ $t('reports.expand.algorithm') }}: {{ item.rfq_lastquote.algo.algo_label }}
                      </span>
                    </v-col> -->
  
                    <v-col cols="3">
                      <span>
                        {{ $t('reports.expand.cfxIndex') }}: -
                      </span>
                    </v-col>
  
                    <v-col cols="3">
                      <span>
                        {{ $t('reports.expand.bestRate') }}: {{ item.best_rate }}
                      </span>
                    </v-col>
  
                    <v-col cols="3">
                      <span>
                        {{ $t('reports.expand.side') }}: 
                        <span
                          :class="{ 
                            'alive--text': item.rfq_side === 'BUY',
                            'orangeColor--text': item.rfq_side === 'SELL'
                          }"
                        >
                          {{ rfqSide(item.rfq_side) }}
                        </span>
                      </span>
                    </v-col>
                  </v-row>
                </div>
  
                <div class="text-h5 font-weight-light mb-5">
                  {{ $t('reports.expand.rates.title') }}
                </div>
                
                <v-card elevation="0" outlined>
                  <v-simple-table style="background: #121212;">
                    <template v-slot:default>
                      <thead>
                        <tr>
                          <th class="text-left">
                            {{ $t('quoteInfo.status') }}
                          </th>
                          <th class="text-left">
                            {{ $t('quoteInfo.bankName') }}
                          </th>
                          <th class="text-left">
                            {{ $t('quoteInfo.bankRate') }}
                          </th>
                          <th class="text-left">
                            {{ $t('quoteInfo.spreadAvg') }}
                          </th>
                          <th class="text-left">
                            {{ $t('quoteInfo.spreadPtax') }}
                          </th>
                          <th class="text-left">
                            {{ $t('quoteInfo.bankLastUpdate') }}
                          </th>
                          <!-- <th class="text-left">
                            {{ $t('quoteInfo.bankTimeDue') }}
                          </th>
                          <th class="text-left">
                            {{ $t('quoteInfo.id') }}
                          </th> -->
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          v-for="rfq in item.rfq_lastquote.quote_list"
                          :key="rfq.quote_id"
                        >
                          <td>
                            <v-chip
                              small
                              :text-color="rfq.deal ? 'primary' : '#BDBDBD'"
                              :color="rfq.deal ? 'primaryTransp' : '#212121'"
                            >
                              <span class="font-weight-bold">
                                {{ rfq.deal ? $t('reports.expand.rates.accepted').toUpperCase() : $t('reports.expand.rates.declined').toUpperCase() }}
                              </span>
                            </v-chip>
                          </td>
                          <td>{{ rfq.bank_label }}</td>
                          <td>{{ rfq.quote_bank_rate }}</td>
                          <td>{{ rfq.spread_avg_spot }}</td>
                          <td>{{ rfq.spread_ptax }}</td>
                          <td>{{ rfq.quote_bank_time | formatDate }}</td>
                          <!-- <td>{{ rfq.quote_bank_timedue | formatDate }}</td>
                          <td>{{ rfq.quote_id }}</td> -->
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                </v-card>
              </td>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
  
      <v-col
        xs="0"
        sm="0"
        md="0"
        lg="1"
        xl="1"
      >
      </v-col>
    </v-row>

    <v-dialog
      v-model="isGrid"
      persistent
      max-width="1500"
      overlay-opacity="0.59"
    >
      <v-card
        class="d-flex flex-column justify-space-between"
        elevation="10"
      >
        <v-img
          src="./../assets/clearfx.ai-black.png"
          aspect-ratio="16/9"
          max-height="50"
          class="mt-8"
          contain
        ></v-img>

        <v-card-title class="text-h6 justify-center font-weight-bold mb-5">
          {{ $t('reportDialog.title') }}
        </v-card-title>

        <v-card-text class="text-subtitle-1 text-center px-6">
          {{ $t('reportDialog.instruction.instructionOne') }}
          <br><br>
          {{ $t('reportDialog.instruction.instructionTwo') }}
          <br><br>
          {{ $t('reportDialog.instruction.instructionThree') }}
        </v-card-text>

        <v-card-text class="">
          <v-row class="d-flex justify-center" no-gutters>
            <v-col cols="6" class="d-flex flex-column">
              <span class="text-center text-h5 white--text mb-4">{{ $t('reportDialog.reportsView') }}</span>

              <v-img
                class="cursor-pointer"
                src="./../assets/report_view.png"
                max-height="250"
                contain
                @click="overlayOne = !overlayOne"
              ></v-img>

              <v-overlay
                :z-index="zIndex"
                :value="overlayOne"
              >
                <v-img
                class="cursor-pointer"
                src="./../assets/report_view.png"
                max-height="700"
                contain
                @click="overlayOne = false"
                ></v-img>
              </v-overlay>
              
            </v-col>
            <v-col cols="6" class="d-flex flex-column">
              <span class="text-center text-h5 white--text mb-4">{{ $t('reportDialog.reportsPdf') }}</span>

              <v-img
                class="cursor-pointer"
                src="./../assets/report_pdf.png"
                max-height="250"
                contain
                @click="overlayTwo = !overlayTwo"
              ></v-img>

              <v-overlay
                :z-index="zIndex"
                :value="overlayTwo"
              >
                <v-img
                class="cursor-pointer"
                src="./../assets/report_pdf.png"
                max-height="700"
                contain
                @click="overlayTwo = false"
                ></v-img>
              </v-overlay>
            </v-col>
          </v-row>
        </v-card-text>

        <v-card-actions class="d-flex pb-6">
          <v-spacer></v-spacer>
          <v-btn
            text
            @click="redirectToFxMarket"
          >
            {{ $t('reportDialog.back') }}
          </v-btn>
          <v-btn
            color="primary"
            class="font-weight-bold"
            @click="redirectToProPurchase"
          >
            {{ $t('reportDialog.getGridPro') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import * as utils from "./../utils.js"
import { mapActions, mapState } from "pinia";
import { useAlgoRFQStore, useUtilsStore, useAuthStore } from "./../store/index";
import { exportPDF } from './../exportRfqsReportToPDF.js';

export default {
  filters: {
    formatDate (timestamp) {
      return utils.getLocaleFormattedDateTime(timestamp)
    },
    formatPrice (price) {
      return utils.formatPrice(price)
    }
  },
  data () {
    return {
      // headers: [
      //   { text: "Deal Time", value: "rfq_deal_time", sortable: false },
      //   // { text: "Status", value: "rfq_deal", sortable: false },
      //   { text: "CCY", value: "rfq_ccy", sortable: false },
      //   // { text: "Side", value: "rfq_side", sortable: false },
      //   { text: "Amount CCY", value: "rfq_amount", sortable: false },
      //   { text: "Settlement", value: "rfq_spotsett", sortable: false },
      //   { text: "ID", value: "rfq_id", sortable: false }
      // ],
      items: [
        /*
        {
          "rfq_ccy": "USD", // OK
          "rfq_side": "BUY", // OK
          "rfq_algoid": 0, // OK
          "rfq_amount": 456464.56, // OK
          "rfq_spotsett": "d+2", // OK
          "rfq_id": "5d994cfb-5dcb-4c45-8cd5-393cb1b2e7a0", // OK
          "rfq_lastquote": {
            "quote_resp_id": "9cfb86d5-8366-4e67-9ca0-86c643bdf128",
            "quote_list": [
              {
                "quote_id": "62cb8ff3-aadb-44d2-9d8a-04de471cbbd8",
                "quote_bank_id": "a2249a6f",
                "quote_bank_rate": 5.0641,
                "quote_bank_time": 1717447798.333726,
                "quote_bank_timedue": 1717447888.333727
              },
              {
                "quote_id": "a3820aa2-8a50-43cd-b869-ed05d80c66f5",
                "quote_bank_id": "7c4a0ca4",
                "quote_bank_rate": 5.0645,
                "quote_bank_time": 1717447798.3337574,
                "quote_bank_timedue": 1717447888.3337579
              },
              {
                "quote_id": "cc79ee29-7970-4e7e-b3e2-4f347cab4b56",
                "quote_bank_id": "b1348c9a",
                "quote_bank_rate": 5.0661,
                "quote_bank_time": 1717447798.3337772,
                "quote_bank_timedue": 1717447888.3337774
              },
              {
                "quote_id": "d3365340-7c25-4054-adb7-3df946d4c881",
                "quote_bank_id": "729f8918",
                "quote_bank_rate": 5.062,
                "quote_bank_time": 1717447798.3337924,
                "quote_bank_timedue": 1717447888.3337927
              }
            ],
            "meta": {
              "a2249a6f": {
                "bank_id": "a2249a6f",
                "bank_label": "BANCO DO BRASIL S.A.",
                "bank_enable": 1,
                "bank_tag": ""
              },
              "7c4a0ca4": {
                "bank_id": "7c4a0ca4",
                "bank_label": "BANCO SANTANDER (BRASIL) S.A.",
                "bank_enable": 0,
                "bank_tag": ""
              },
              "b1348c9a": {
                "bank_id": "b1348c9a",
                "bank_label": "ITAU UNIBANCO S.A.",
                "bank_enable": 0,
                "bank_tag": ""
              },
              "729f8918": {
                "bank_id": "729f8918",
                "bank_label": "BANCO DAYCOVAL S.A.",
                "bank_enable": 0,
                "bank_tag": ""
              }
            },
            "algo": {
              "algo_id": 0,
              "algo_label": "ALGO_GRID"
            },
            "quote_rfqid": "5d994cfb-5dcb-4c45-8cd5-393cb1b2e7a0"
          },
          "rfq_deal": 1,
          "rfq_deal_quoteid": "d3365340-7c25-4054-adb7-3df946d4c881",
          "rfq_deal_time": 1717447799.9494324 // OK
        },
        {
          "rfq_ccy": "USD",
          "rfq_side": "SELL",
          "rfq_algoid": 0,
          "rfq_amount": 456464.56,
          "rfq_spotsett": "d+2",
          "rfq_id": "6a91be96-e650-4c74-9c12-a9406a70488a",
          "rfq_lastquote": {
            "quote_resp_id": "d5f15c17-e3c7-4e16-be5f-4cee4447720b",
            "quote_list": [
              {
                "quote_id": "e9ee287f-5f3a-4670-8802-866dbd172596",
                "quote_bank_id": "a2249a6f",
                "quote_bank_rate": 5.0628,
                "quote_bank_time": 1717447814.22312,
                "quote_bank_timedue": 1717447904.2231214
              },
              {
                "quote_id": "03a88141-a5b3-490f-93f7-cdd83a8d816a",
                "quote_bank_id": "7c4a0ca4",
                "quote_bank_rate": 5.0604,
                "quote_bank_time": 1717447814.2231433,
                "quote_bank_timedue": 1717447904.223144
              },
              {
                "quote_id": "e4a6c4f9-64d6-45f5-b5f0-c57ff269b0f7",
                "quote_bank_id": "b1348c9a",
                "quote_bank_rate": 5.0691,
                "quote_bank_time": 1717447814.2231617,
                "quote_bank_timedue": 1717447904.2231622
              },
              {
                "quote_id": "f9fc49b1-04c4-4bbd-904c-18debaedc8f7",
                "quote_bank_id": "729f8918",
                "quote_bank_rate": 5.0622,
                "quote_bank_time": 1717447814.2231798,
                "quote_bank_timedue": 1717447904.2231803
              }
            ],
            "meta": {
              "a2249a6f": {
                "bank_id": "a2249a6f",
                "bank_label": "BANCO DO BRASIL S.A.",
                "bank_enable": 1,
                "bank_tag": ""
              },
              "7c4a0ca4": {
                "bank_id": "7c4a0ca4",
                "bank_label": "BANCO SANTANDER (BRASIL) S.A.",
                "bank_enable": 0,
                "bank_tag": ""
              },
              "b1348c9a": {
                "bank_id": "b1348c9a",
                "bank_label": "ITAU UNIBANCO S.A.",
                "bank_enable": 0,
                "bank_tag": ""
              },
              "729f8918": {
                "bank_id": "729f8918",
                "bank_label": "BANCO DAYCOVAL S.A.",
                "bank_enable": 0,
                "bank_tag": ""
              }
            },
            "algo": {
              "algo_id": 0,
              "algo_label": "ALGO_GRID"
            },
            "quote_rfqid": "6a91be96-e650-4c74-9c12-a9406a70488a"
          },
          "rfq_deal": 1,
          "rfq_deal_quoteid": "e9ee287f-5f3a-4670-8802-866dbd172596",
          "rfq_deal_time": 1717447816.1062646
        }
        */
      ],
      expanded: [],
      date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substring(0, 10),
      date2: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substring(0, 10),
      menu2: false,
      menu3: false,
      isGrid: false,
      overlayOne: false,
      overlayTwo: false,
      zIndex: 1,
    }
  },
  computed: {
    reportsData () {
      const reports = JSON.parse(localStorage.getItem('reports')) || []
      if (!reports) {
        return []
      }
      return reports.map(
        (element) => {
          const quoteList = element.rfq_lastquote.quote_list
          const quoteDealId = element.rfq_deal_quoteid
          for (let quote of quoteList) {
            // quote["bank_label"] = quote.bank_label
            quote["deal"] = quoteDealId === quote.quote_id
            quote["spread_avg_spot"] = "-"
            quote["spread_ptax"] = "-"
          }
          const bestRate = quoteList.reduce((prev, current) => {
            const prevRate = prev && !isNaN(prev.quote_bank_rate) ? parseFloat(prev.quote_bank_rate) : Infinity
            const currentRate = !isNaN(current.quote_bank_rate) ? parseFloat(current.quote_bank_rate) : Infinity

            return prevRate < currentRate ? prev : current
          }, undefined)
          element["best_rate"] = bestRate.quote_bank_rate
          element["rfq_lastquote"]["quote_list"] = quoteList
          return element
        }
      )
    },
    ...mapState(useUtilsStore, [
      "orangeColor",
      "yellowTransp",
      "aliveTransp"
    ]),
    ...mapActions(useAuthStore, [
      "getUserRoleFromLocalStorage"
    ]),
    now () {
      return utils.getLocaleFormattedDateTime()
    },
    headers() {
      return [
        { text: this.$t('quoteInfo.dealTime'), value: "rfq_deal_time", sortable: false },
        // { text: "Status", value: "rfq_deal", sortable: false },
        { text: this.$t('quoteInfo.ccy'), value: "rfq_ccy", sortable: false },
        // { text: "Side", value: "rfq_side", sortable: false },
        { text: this.$t('quoteInfo.amountCcy'), value: "rfq_amount", sortable: false },
        { text: this.$t('quoteInfo.settlement'), value: "rfq_spotsett", sortable: false },
        { text: this.$t('quoteInfo.id'), value: "rfq_id", sortable: false }
      ]
    },
    locale() {
      const savedLocale = localStorage.getItem('userLanguage');
      return savedLocale || 'en';
    }
  },
  mounted () {
    this.isGridpro()
    this.orderList(100)
      .then((response) => {
        this.items = response.data.elements
      })
      .catch((error) => {
        console.log(error)
      })
      .finally(() => {})
  },
  methods: {
    ...mapActions(useAlgoRFQStore, [
      "orderList"
    ]),
    exportPDF() {
      exportPDF(this.reportsData);
    },
    expandAll() {
      this.expanded = this.reportsData
    },
    contractAll() {
      this.expanded = []
    },
    rfqSide (rfq_side) {
      if (rfq_side === 'SELL') {
        return this.$i18n.t('side.sell').toUpperCase()
      }
      else {
        return this.$i18n.t('side.buy').toUpperCase()
      }
    },
    isGridpro () {
      const role = this.getUserRoleFromLocalStorage
      if (role === 'grid') {
        this.isGrid = true
      }
    },
    redirectToProPurchase () {
      const url = '#'
      window.open(url, "_blank")
    },
    redirectToFxMarket () {
      const url = 'fxmarket'
      this.$router.push({ path: url });
    }
  }
}
</script>

<style scoped>
</style>
