<template>
  <div style="width: 150px;" class="d-flex flex-column">
    <div 
      class="text-caption mx-auto sparkline-title" 
      style="line-height: 14px;"
    >
      {{ ticker }} 
      <!-- <span
        :class="{ 
          'alive--text': changeValue < 0,
          'orangeColor--text': changeValue > 0,
        }"
      >
        {{ changeValue > 0 ? `+${changeValue}` : changeValue }}
      </span> -->
    </div>
    <div
      class="text-h5 d-flex justify-center"
      :class="{ 
      }"
    >
      {{ changeValue }}
    </div>
    <!-- <v-chart class="chart" autoresize :option="sparkLine"/> -->
  </div>
</template>

<script>
// import { use } from "echarts/core";
// import { CanvasRenderer } from "echarts/renderers";
// import { LineChart } from "echarts/charts";
// import {
//   TooltipComponent,
//   LegendComponent,
//   GridComponent,
//   GraphicComponent,
// } from "echarts/components";
import { mapState } from "pinia";
import { useUtilsStore } from "./../store/index";
// import VChart from "vue-echarts";

// use([
//   CanvasRenderer,
//   LineChart,
//   GridComponent,
//   TooltipComponent,
//   LegendComponent,
//   GraphicComponent,
// ]);

export default {
  props: {
    interval: {
      required: false,
      default: 0.0001
    },
    options: {
      required: false,
      type: Object
    },
    sparklineData: {
      required: true,
      type: Object
    },
    ticker: {
      required: true,
      type: String
    }
  },
  // components: {
  //   VChart
  // },
  data () {
    return {
    }
  },
  computed: {
    ...mapState(useUtilsStore, [
      "orangeColor",
      "aliveColor"
    ]),
    sparkLine () {
      const sparkLine = JSON.parse(JSON.stringify(this.sparklinesOptions))
      return sparkLine
    },
    sparklinesOptions () {
      const data = {
        xAxis: {
          type: "category",
          boundaryGap: false,
          data: [
            1,
            2,
            3,
            4,
            5,
            6,
            7,
            8,
            9,
            10,
            11,
            12,
            13,
            14,
            15,
            16,
            17,
            18,
            19,
            20,
            21,
            22,
            23,
            24,
            25,
            26,
            27,
            28,
            29,
            30
          ],
          splitLine:{ show: false },
          axisLine: { show: false },
          axisTick: { show: false },
          axisLabel: { show: false },
          max: 30
        },
        yAxis: {
          type: "value",
          max: Math.max(...this.sparklineData.series)+(this.interval*3),
          min: Math.min(...this.sparklineData.series)-(this.interval*3),
          interval: this.interval,
          boundaryGap: [0, "100%"],
          splitLine:{ show: false },
          axisLine: { show: false },
          axisTick: { show: false },
          axisLabel: { show: false },
        },
        max: "dataMax",
        series: [
          {
            name: "Fake Data",
            type: "line",
            symbol: "none",
            sampling: "lttb",
            itemStyle: {
              color: this.changeValue > 0 ? this.orangeColor : this.aliveColor
            },
            areaStyle: {
              color: {
                type: "linear",
                x: 0,
                y: 0,
                x2: 0,
                y2: 1,
                colorStops: [
                  {
                    offset: 0, color: this.changeValue > 0 ? this.orangeColor : this.aliveColor,
                  }, 
                  {
                    offset: 1, color: "#000"
                  }
                ],
                global: false // default is false
              },
            },
            data: this.sparklineData.series
          }
        ]
      }
      return data
    },
    // changeValue () {
    //   const length = this.sparklineData.series.length
    //   const last = this.sparklineData.series[length - 1]
    //   const lastButOne = this.sparklineData.series[length - 2]
    //   const result = last - lastButOne
    //   return Number(result.toFixed(4))
    // }
    changeValue() {
      if (!this.sparklineData || Object.keys(this.sparklineData).length === 0) {
        return
      }

      const keys = Object.keys(this.sparklineData)
      const firstKey = keys[0]
      const firstKeyData = Number(this.sparklineData[firstKey]['1. open'])

      if(this.ticker === "JPY/USD") return firstKeyData.toFixed(6)

      return firstKeyData.toFixed(4)
    },
    sparklineDataIntraday() {
      if (!this.sparklineData || Object.keys(this.sparklineData).length === 0) {
        return
      }

      const today = new Date()

      today.setHours(0, 0, 0, 0)
      const todayStartBRT = today.getTime()

      const filteredData = []
      for (const key in this.sparklineData) {
        const dateUtc = new Date(key).getTime()
        const dateBrl = dateUtc - (3 * 60 * 60 * 1000)
        if (dateBrl >= todayStartBRT) {
          filteredData.push({
            value: this.sparklineData[key]['1. open'],
          })
        }
      }

      return filteredData.reverse()
    },
  },
  methods: {
  }
}
</script>

<style scoped>
  .chart {
    height: 70px
  }
  .sparkline-title:hover {
    font-weight: 700
  }
</style>